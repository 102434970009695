define("discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-llms/show", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AiLlmsListEditor @llms={{this.allLlms}} @currentLlm={{this.model}} />
  */
  {
    "id": "tdQ3gCh/",
    "block": "[[[8,[39,0],null,[[\"@llms\",\"@currentLlm\"],[[30,0,[\"allLlms\"]],[30,0,[\"model\"]]]],null]],[],false,[\"ai-llms-list-editor\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-llms/show.hbs",
    "isStrictMode": false
  });
});