define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-personas-show", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model(params) {
      const allPersonas = this.modelFor("adminPlugins.show.discourse-ai-personas");
      const id = parseInt(params.id, 10);
      return allPersonas.findBy("id", id);
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set("allPersonas", this.modelFor("adminPlugins.show.discourse-ai-personas"));
    }
  });
});