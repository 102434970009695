define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-personas-new", ["exports", "discourse/lib/constants", "discourse/routes/discourse"], function (_exports, _constants, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    async model() {
      const record = this.store.createRecord("ai-persona");
      record.set("allowed_group_ids", [_constants.AUTO_GROUPS.trust_level_0.id]);
      record.set("rag_uploads", []);
      // these match the defaults on the table
      record.set("rag_chunk_tokens", 374);
      record.set("rag_chunk_overlap_tokens", 10);
      record.set("rag_conversation_chunks", 10);
      return record;
    },
    setupController(controller, model) {
      this._super(controller, model);
      controller.set("allPersonas", this.modelFor("adminPlugins.show.discourse-ai-personas"));
    }
  });
});